import { withDebounce } from "common/with-debounce";
import { withValue, WithValue } from "common/with-value";
import { InputWithSubmit, OnSubmit } from ".";

type Value = string | number;

interface PropTypes {
  onSubmit?: OnSubmit;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  maxLength?: number;
  inputId?: string;
  autoComplete?: string;
  label?: string;
}

const StringComponent = ({
  onSubmit,
  disabled,
  placeholder,
  className,
  maxLength,
  inputId,
  label,
  autoComplete,
  value,
  onChangeSetValue,
}: PropTypes & WithValue<Value>) => (
  <InputWithSubmit
    type="text"
    className={className}
    label={label}
    maxLength={maxLength}
    inputId={inputId}
    disabled={disabled}
    placeholder={placeholder}
    onSubmit={onSubmit}
    submitOnBlur={true}
    autoComplete={autoComplete}
    value={value ? value.toString() : ""}
    onChange={onChangeSetValue}
  />
);

const BaseStringInput = withValue<Value, PropTypes>(
  StringComponent,
  "StringInput",
);

export const StringInput = withDebounce(BaseStringInput);
