import * as R from "ramda";
import { Component } from "common/component";
import { getChildren } from "common/functions/children";
import { BadgeType } from "common/types/badges";
import { classNames } from "common/utils/array";
import { isTabElement, TabElement } from "./tab";

import "./tabs.scss";

interface TabsProps {
  defaultValue?: string;
  parentExpectedTab?: string;
  onChange?: (selectedTab: string) => void;
  className?: string;
  evenSpacedTabs?: boolean;
  smallFont?: boolean;
  children?: TabElement | TabElement[];
}

interface StateType {
  selectedTab: string;
}

const getSelectedTab = (props: TabsProps) => {
  const { children, defaultValue } = props;

  const tabs = getChildren(children).filter(isTabElement);
  if (!tabs.length) return undefined;

  const tab =
    R.find((t) => t.props.value === defaultValue, tabs) ||
    R.find((t) => !!t.props.value, tabs);
  return tab.props.value;
};

const icon = <i className="fa fa-exclamation-circle" aria-hidden="true" />;
const getBadgeIcon = (badge: string, badgeType: BadgeType) => (
  <span className={classNames(["badge pull-right x-count", badgeType])}>
    {badge}
  </span>
);

export class Tabs extends Component<TabsProps, StateType> {
  static readonly displayName = "Tabs";

  constructor(props: TabsProps) {
    super(props);
    this.state = { selectedTab: getSelectedTab(this.props) };
  }

  onClickTab = (selectedTab: string) => () => {
    this.setState({ selectedTab });
    if (this.props.onChange) this.props.onChange(selectedTab);
  };

  render() {
    const {
      className = "",
      children,
      evenSpacedTabs,
      smallFont,
      parentExpectedTab,
      defaultValue,
    } = this.props;

    const selectedTab =
      parentExpectedTab && parentExpectedTab !== this.state.selectedTab
        ? defaultValue
        : this.state.selectedTab || getSelectedTab(this.props);

    const tabs = getChildren(children).filter(isTabElement);
    if (!tabs.length) return null;

    const spacing = evenSpacedTabs ? " x-tab-even-spaced" : "";
    const font = smallFont ? " x-tab-small-font" : "";
    const cursor = tabs.length > 1 ? " x-pointer" : "";

    const buttons = tabs.map((t) => {
      const { value, label, warningIcon, badge, badgeType } = t.props;
      const isSelected = selectedTab === value;
      const selected = isSelected ? " x-tab-selected" : cursor;
      const tabClasses = spacing + font + selected;

      return (
        <div
          key={label}
          className={`x-flex-center-center x-tab-button qa-tab${tabClasses}`}
          onClick={isSelected ? undefined : this.onClickTab(value)}
        >
          {label}
          {warningIcon && icon}
          {getBadgeIcon(badge, badgeType)}
        </div>
      );
    });

    const content = R.find((t) => t.props.value === selectedTab, tabs);

    return (
      <div className={`x-tabs qa-tabs ${className}`}>
        <div className="x-tab-buttons">{buttons}</div>
        <div className="x-tab-content">{content}</div>
      </div>
    );
  }
}
