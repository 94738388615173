export const getActionLabel =
  (categoryCanArchive: boolean, categoryName?: string) =>
  (key: string): string => {
    switch ((key || "").toLowerCase()) {
      case "addcomments":
        return _("Add Comments");
      case "approve":
        return _("Approve");
      case "close":
        return _("Close");
      case "create":
        return _("Create");
      case "createshared":
        return _("Create Shared");
      case "delete":
        return categoryName === "Users"
          ? _("Disable")
          : categoryCanArchive
            ? _("Archive")
            : _("Delete");
      case "email":
        return _("E-mail");
      case "exporttocsv":
        return _("Export to CSV");
      case "exporttopdf":
        return _("Export to PDF");
      case "importcsv":
        return _("Import CSV");
      case "liveassetsettings":
        return _("LIVE-Asset Settings");
      case "open":
        return _("Open");
      case "receive":
        return _("Receive");
      case "reject":
        return _("Reject");
      case "reopen":
        return _("Re-open");
      case "reschedule":
        return _("Re-schedule");
      case "restore":
        return _("Restore");
      case "schedule":
        return _("Schedule");
      case "send":
        return _("Submit PO");
      case "sign":
        return _("Signature sign-off");
      case "submitrequisition":
        return _("Submit");
      case "submitpartcount":
        return _("Submit Count");
      case "submitpartscount":
        return _("Submit Ready Counts");
      case "switchform":
        return _("Switch Form");
      case "update":
        return categoryCanArchive ? _("Edit") : _("Update");
      case "updatesettings":
        return _("Update Settings");
      case "viewinliveasset":
        return _("View in LIVE-Asset");
      case "viewlaborrates":
        return _("View labor rates");
      default:
        return key;
    }
  };
