import * as R from "ramda";
import { useState } from "react";
import { searchApi } from "common/api/search";
import { ApiError } from "common/ui/api-error";
import { behaveAs } from "common/entities";
import { defaultFor, getLocalizedName } from "common/index";
import { getBatchQuery } from "common/record/form/content/related/part-charge/functions";
import { isValueValid } from "common/record/form/content/related/parts-to-be-counted/functions";
import { ForeignKey } from "common/types/foreign-key";
import { VerticalField } from "common/ui/field";
import { QuerySelector } from "common/widgets/selector/query-selector";
import { InputWidget } from "common/form/widget/input-widget";
import {
  getAdjustmentEntity,
  getFormatBatchFn,
} from "common/record/form/content/related/stock/functions";
import { Required } from "common/widgets/required";
import { cycleCountApi } from "common/api/cycle-count";
import { Entity } from "common/entities/types";
import { getFkId } from "common/functions/foreign-key";
import { Properties } from "common/types/records";
import { Context } from "common/types/context";
import { ActionButtonSmall, CancelButtonLarge } from "common/ui/buttons";
import { LoadingButton } from "common/widgets/loading-button";

interface PropTypes {
  context: Context;
  entity: Entity;
  recordId: string;
  onCancel: () => any;
  onSubmit: () => any;
  properties: Properties;
  onHand: number;
  lastCost?: number;
  manualBatchSelection: boolean;
}

export const SubmitActionContent = (props: PropTypes) => {
  const {
    context,
    entity,
    onSubmit,
    onCancel,
    recordId,
    properties,
    onHand,
    lastCost,
    manualBatchSelection,
  } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [batch, setBatch] = useState<ForeignKey>(undefined);
  const [cost, setCost] = useState(undefined);

  const partLocationEntityName = entity.arguments.stockEntity;
  const partLocationId = getFkId(properties?.partLocationId);
  const batchId = getFkId(batch);

  const applyLastCost = () => {
    if (!cost) setCost(lastCost);
  };

  const submit = () => {
    setLoading(true);
    setError(undefined);

    cycleCountApi(context.apiCall)
      .submit(entity.name, recordId, {
        finalCount: properties?.finalCount,
        cost: +cost,
        batchId: batchId === "new" ? undefined : batchId,
      })
      .then(onSubmit)
      .catch(setError)
      .finally(() => setLoading(false));
  };

  const onHandDelta = properties?.finalCount - onHand;
  const batchEntity =
    R.values(context.entities).find(
      (entity) =>
        behaveAs("Batch", entity) &&
        entity.arguments.stockEntity === partLocationEntityName,
    ) ?? defaultFor<Entity>();
  const batchSelectorDefaults: ForeignKey[] = [
    { id: "new", number: 0, title: _("New") },
  ];

  const batchSelector = manualBatchSelection ? (
    <VerticalField
      label={getLocalizedName(batchEntity)}
      input={
        <Required data-testid="batch-selector" value={batch}>
          <QuerySelector
            context={context}
            query={
              partLocationId
                ? getBatchQuery(batchEntity, partLocationId)
                : undefined
            }
            runQuery={searchApi(context.apiCall).runQuery}
            defaults={onHandDelta >= 0 ? batchSelectorDefaults : undefined}
            formatOption={getFormatBatchFn(context)}
            value={batch}
            onChange={setBatch}
          />
        </Required>
      }
    />
  ) : undefined;

  const fullAdjustmentEntity = getAdjustmentEntity(
    context.entities,
    context.entities[partLocationEntityName],
  );
  const unitCostColumn = R.find(
    (c) => c.name === "cost",
    fullAdjustmentEntity.columns,
  );
  const showCost =
    properties?.finalCount > onHand &&
    (!manualBatchSelection || batchId === "new");

  const costInput = showCost ? (
    <VerticalField
      label={_("Unit cost")}
      input={
        <Required value={cost}>
          <InputWidget
            context={context}
            column={unitCostColumn}
            disabled={false}
            validate={true}
            buffer={false}
            formValidation={undefined}
            onFormValidationChange={undefined}
            value={cost}
            onChange={setCost}
          />
        </Required>
      }
    />
  ) : undefined;

  const isFormValid = isValueValid({
    onHand,
    finalCount: properties?.finalCount,
    cost,
    manualBatchSelection,
    batch,
  });

  return (
    <div className="x-cycle-count-submit-wrapper">
      <div className="x-form-content">
        {error ? <ApiError error={error} /> : undefined}
        {batchSelector}
        {showCost && lastCost ? (
          <ActionButtonSmall
            onClick={applyLastCost}
            disabled={cost !== undefined}
          >
            {_("Apply Last Cost")}
          </ActionButtonSmall>
        ) : undefined}
        {costInput}
      </div>
      <div className="x-form-footer">
        <div className="x-form-footer-content x-flex x-flex-end-center">
          <CancelButtonLarge onClick={onCancel} />
          <LoadingButton
            loadingText={_("Submitting")}
            idleText={_("Submit")}
            loading={loading}
            onClick={submit}
            disabled={loading || !isFormValid}
            type="action"
            size="large"
          />
        </div>
      </div>
    </div>
  );
};
