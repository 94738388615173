import { Uint } from "common/widgets/number";
import { CancelButton } from "common/ui/buttons";
import { ValueComponent, ValueProps } from "common/with-value-for";

import "./index.scss";

interface PropTypes {
  itemsPerPage: number;
  totalItems: number;
  loading?: boolean;
  showError?: (index: number) => boolean;
  refresh?: any; // this is to trigger a refresh when the prop changes.
}

type Props = PropTypes & ValueProps<number>;

interface StateType {
  currentPage: number;
}

const displayPage = (pageIndex: number) => `${pageIndex + 1}`;

export class Pagination extends ValueComponent<number, Props, StateType> {
  static readonly displayName = "Pagination";

  constructor(props: Props) {
    super(props);

    this.state = {
      currentPage: props.value ?? 0,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.value !== prevProps.value) {
      this.setState({ currentPage: this.props.value });
    }
  }

  getOnChangePageFn = (pageNumber: number) => () => this.setValue(pageNumber);

  onCurrentPageChange = (index: string) => {
    this.setState({ currentPage: index ? +index - 1 : undefined });
  };

  onCurrentPageSubmit = () => {
    const { currentPage } = this.state;

    if (currentPage !== undefined) {
      this.setValue(currentPage);

      document
        .querySelector(".x-pagination-current-page")
        ?.dispatchEvent(new MouseEvent("click"));
    }
  };

  createCurrentPageButton = (index: number) => {
    const { showError, totalItems, itemsPerPage } = this.props;
    const { currentPage } = this.state;
    const numberOfPages = Math.ceil(totalItems / itemsPerPage);

    const hasError = showError && showError(index);
    const errorClass = hasError ? "x-page-selected-error" : "";

    return (
      <>
        <button
          className={`x-button-action x-dropdown-btn x-pagination-current-page dropdown-toggle qa-page qa-page-${index} ${errorClass}`}
          type="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          {displayPage(index)}
        </button>
        <div className="dropdown-menu x-pagination-menu">
          <div className="x-pagination-menu-content">
            Go to page{" "}
            <Uint
              value={currentPage !== undefined ? currentPage + 1 : undefined}
              maxValue={numberOfPages}
              minValue={1}
              submitOnBlur={false}
              onChange={this.onCurrentPageChange}
              onSubmit={this.onCurrentPageSubmit}
            />
          </div>
        </div>
      </>
    );
  };

  createPageButton = (index: number) => {
    const { loading, showError } = this.props;
    const hasError = showError && showError(index);
    const errorClass = hasError ? "x-page-error" : "";

    return (
      <CancelButton
        className={`x-margin-left-2 x-margin-right-2 qa-page qa-page-${index} ${errorClass}`}
        onClick={this.getOnChangePageFn(index)}
        disabled={loading}
      >
        {displayPage(index)}
      </CancelButton>
    );
  };

  render() {
    const { totalItems, itemsPerPage, value = 0, loading } = this.props;

    const numberOfPages = Math.ceil(totalItems / itemsPerPage);
    const lastPage = numberOfPages - 1;

    return (
      <div className="x-pagination">
        <CancelButton
          className="x-margin-right-15 qa-navigation-prev"
          disabled={loading || value === 0}
          onClick={this.getOnChangePageFn(value - 1)}
        >
          <i className="fa fa-chevron-left" />
        </CancelButton>

        {value >= 3 && this.createPageButton(0)}
        {value > 3 && <span> ... </span>}
        {value >= 2 && this.createPageButton(value - 2)}
        {value >= 1 && this.createPageButton(value - 1)}
        {this.createCurrentPageButton(value)}
        {lastPage - value >= 1 && this.createPageButton(value + 1)}
        {lastPage - value >= 2 && this.createPageButton(value + 2)}
        {lastPage - value > 3 && <span> ... </span>}
        {lastPage - value >= 3 && this.createPageButton(lastPage)}

        <CancelButton
          className="x-margin-left-15 qa-navigation-next"
          disabled={loading || value === lastPage}
          onClick={this.getOnChangePageFn(value + 1)}
        >
          <i className="fa fa-chevron-right" />
        </CancelButton>
      </div>
    );
  }
}
