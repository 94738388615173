import * as R from "ramda";
import { Component } from "react";
import { defaultFor } from "common";
import { avatarApi, avatarUrl } from "common/api/avatar";
import { profileApi } from "common/api/profile";
import { FormFooter } from "common/form/footer";
import { merge2 } from "common/merge";
import { Context } from "common/types/context";
import { FileType } from "common/types/media";
import { CancellablePromise } from "common/types/promises";
import { PasswordPolicy } from "common/types/settings";
import { applyTheme } from "common/utils/themes";
import { ApiError } from "common/ui/api-error";
import {
  filterByEnabledForSidePanel,
  getAvailableUserMenuSettings,
  getMergedMenuSettings,
} from "x/layout/navigation/functions";
import { Ribbon } from "x/layout/ribbon";
import { Crumb } from "x/layout/ribbon/breadcrumb";
import { isValid, UserProfileForm, UserProfileFormValue } from "./form";

interface Dependencies {
  passwordPolicy: PasswordPolicy;
}

interface PropTypes {
  context: Context;
  goBack: (event: string) => void;
  onImageChanged: (image: string) => void;
  dependencies: Dependencies;
}

interface StateType {
  form?: UserProfileFormValue;
  saving?: boolean;
  error?: any;
}

export class UserProfile extends Component<PropTypes, StateType> {
  static readonly displayName = "UserProfile";
  context: any;
  constructor(props: PropTypes) {
    super(props);
    this.state = {
      form: this.getValue(this.props, undefined),
    };
  }

  componentDidUpdate(prevProps: PropTypes) {
    const newProps = this.props;
    if (prevProps.context !== newProps.context) {
      this.setState({ form: this.getValue(newProps, this.state.form) });
    }
  }

  getValue = (
    props: PropTypes,
    oldValue: UserProfileFormValue,
  ): UserProfileFormValue => {
    const { name, userName, uiFormat, preferenceService, entities } =
      props.context;

    const preferences = preferenceService.get();

    const availableMenuSettings = getAvailableUserMenuSettings(props.context);
    const userMenuSettings = filterByEnabledForSidePanel(
      preferences?.menu,
      entities,
    );

    const menu = userMenuSettings.length
      ? getMergedMenuSettings(availableMenuSettings, userMenuSettings)
      : availableMenuSettings;

    return {
      ...oldValue,
      details: {
        name,
        userName,
        currentPassword: undefined,
        password: undefined,
        confirmPassword: undefined,
        image:
          oldValue && oldValue.details
            ? oldValue.details.image
            : `/${avatarUrl}`,
        uiFormat,
      },
      preferences: { ...preferences, menu },
    };
  };

  onSave = () => {
    const { context, goBack } = this.props;
    const { form } = this.state;
    const { details, preferences } = form;

    // when we save we have to call 2 or 3 promises:
    //  1) set layout
    //  2) set menu
    //  3) set password (only if the password is set)

    this.setState({ saving: true });
    const setDetails =
      !context.isSystem && details
        ? profileApi(context.apiCall).update(R.omit(["image"], details))
        : CancellablePromise.resolve();

    CancellablePromise.all([
      context.preferenceService.set(preferences),
      setDetails,
    ])
      .then(() => {
        this.setState({ saving: false });

        applyTheme(preferences.theme);

        goBack("saved");
      })
      .catch((error) => this.setState({ error, saving: false }));
  };

  onCancel = () => {
    const { goBack } = this.props;
    goBack("cancel");
  };

  setFormImage = (image: string) => {
    const { onImageChanged } = this.props;
    const { form } = this.state;

    onImageChanged(image);
    const newForm = merge2("details", "image", image, form);
    this.setState({ form: newForm });
  };

  uploadAvatar = (file: FileType) => {
    const { context } = this.props;
    return avatarApi(context.apiCall)
      .upload(file)
      .then(this.setFormImage)
      .catch((error) => this.setState({ error }));
  };

  removeAvatar = () => {
    const { context } = this.props;
    return avatarApi(context.apiCall)
      .remove()
      .then(() => this.setFormImage(undefined))
      .catch((error) => this.setState({ error }));
  };

  onChangeFormState = (form: UserProfileFormValue) => {
    this.setState({ form });
  };

  render() {
    const { context, dependencies = defaultFor<Dependencies>() } = this.props;
    const { passwordPolicy } = dependencies;
    const { form, saving, error } = this.state;
    const crumbs: Crumb[] = [{ name: _("User Profile") }];
    return (
      <div className="x-container-with-ribbon">
        <Ribbon onRefresh={undefined} crumbs={crumbs} />
        <div className="x-content-with-ribbon">
          <UserProfileForm
            context={context}
            uploadAvatar={this.uploadAvatar}
            removeAvatar={this.removeAvatar}
            value={form}
            onChange={this.onChangeFormState}
            passwordPolicy={passwordPolicy}
          />
          <FormFooter
            canDelete={false}
            onDelete={undefined}
            isDeleting={false}
            canRestore={false}
            onRestore={undefined}
            isRestoring={false}
            canSave={true}
            onSave={this.onSave}
            isSaving={saving}
            canCancel={true}
            onCancel={this.onCancel}
            isValid={isValid(form, context.isSystem, passwordPolicy)}
            isNew={false}
            footerContent={<ApiError error={error} />}
          />
        </div>
      </div>
    );
  }
}
