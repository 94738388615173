import { recordsApi } from "common/api/records";
import { Entity } from "common/entities/types";
import { Event } from "common/types/events";
import { EventListItem } from "common/record/form/content/audit-trail/event-list/event-list-item";
import { Context } from "common/types/context";
import { ShowMore } from "common/widgets/show-more";
import { ActionButtonSmall } from "common/ui/buttons";
import { showErrorNotification } from "common/ui/notification";

interface PropTypes {
  selectedEvent: Event;
  events: Event[];
  entityName: string;
  context: Context;
  loading: boolean;
  onShowMore: () => void;
  isLastPage: boolean;
  recordId: string;
  getUrl?: (entity: Entity, site: string) => string;
}

export const EventList = ({
  selectedEvent,
  events = [],
  entityName,
  context,
  loading,
  onShowMore,
  isLastPage,
  recordId,
  getUrl,
}: PropTypes) => {
  const list = (
    <ul className="x-list-group qa-list-group">
      {events.map((e, i) => {
        const selectedClass = selectedEvent.id === e.id ? " selected" : "";
        return (
          <li
            key={i}
            className={`x-list-group-item qa-list-group-item${selectedClass}`}
          >
            <EventListItem
              event={e}
              entityName={entityName}
              context={context}
              recordId={recordId}
              getUrl={getUrl}
            />
          </li>
        );
      })}
    </ul>
  );

  const onDownloadFullAuditTrail = () =>
    recordsApi(context.apiCall)
      .downloadFullAuditTrail(entityName, recordId)
      .catch(() =>
        showErrorNotification(_("We could not complete your request")),
      );

  return (
    <div className="x-event-list qa-event-list">
      <div className="x-event-list-header">
        <h3 className="x-event-list-title qa-event-list-title">
          {_("Events")}
        </h3>
        <ActionButtonSmall
          className="x-margin-10 qa-download-all-events"
          onClick={onDownloadFullAuditTrail}
        >
          {_("Download Full Audit Trail")}
        </ActionButtonSmall>
      </div>
      <div className="x-event-list-items">
        {!isLastPage ? (
          <ShowMore action={onShowMore} loading={loading}>
            {list}
          </ShowMore>
        ) : (
          list
        )}
      </div>
    </div>
  );
};

EventList.displayName = "EventList";
