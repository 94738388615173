import { currency } from "common/validate";
import { getValidationWarning } from "common/widgets/number/functions";
import { AlertErrorTip } from "common/widgets/alert";
import { EntityColumn } from "common/entities/entity-column/types";
import { merge2 } from "common/merge";
import { FormValidationProps } from "common/form/types";
import { arrayToString } from "common/utils/array";
import { withDebounce } from "common/with-debounce";
import { ValueComponent, ValueProps } from "common/with-value-for";
import { decimalPlaceholder, formatDecimal } from "common/utils/decimal";
import { isValueValid } from "common/widgets/currency/functions";
import { InputWithSubmit, OnSubmit } from "../input-with-submit";

interface StateType {
  internalValue: string;
}

interface PropTypes extends FormValidationProps {
  column: EntityColumn;
  decimalSeparator: string;
  decimalScale: number;
  currencySymbol: string;
  className?: string;
  onSubmit?: OnSubmit;
  disabled?: boolean;
  hideSymbol?: boolean;
  label?: string;
}

/**
 * Returns true if value has scale incomplete, meaning starting or finishing with a dot.
 * @param decimalValue
 * @returns
 */
const isPartialDecimal = (decimalValue: string) =>
  /(^\.|\.$)/.test(decimalValue);
const normalizeCurrency = (value: string, scale: number) =>
  value && !isValueValid(scale)(value)
    ? formatDecimal(value, scale).toString()
    : value;

class BaseCurrencyInput extends ValueComponent<string, PropTypes, StateType> {
  static readonly displayName = "CurrencyInput";

  state: StateType = {
    internalValue: undefined,
  };

  constructor(props: Readonly<PropTypes & ValueProps<string>>) {
    super(props);

    this.state = {
      internalValue: normalizeCurrency(props.value, props.decimalScale),
    };
  }

  componentDidUpdate(prevProps: Readonly<PropTypes & ValueProps<string>>) {
    const { value, decimalScale } = this.props;
    const { internalValue } = this.state;

    if (prevProps.value !== value && value !== internalValue) {
      this.setState({ internalValue: normalizeCurrency(value, decimalScale) });
    }
  }

  onChange = (inputValue: string = "") => {
    const {
      decimalSeparator,
      decimalScale,
      column,
      formValidation,
      onFormValidationChange,
    } = this.props;

    const normalizedValue = inputValue.replace(decimalSeparator, ".");

    // `1.` is considered "valid" exceptionally, so we let you complete the input
    if (isValueValid(decimalScale)(normalizedValue)) {
      this.setState({ internalValue: normalizedValue });

      const isPartial = isPartialDecimal(normalizedValue);
      if (!isPartial) this.setValue(normalizedValue); // we only emit complete values

      onFormValidationChange?.(
        merge2("invalidFields", column?.name, isPartial, formValidation),
      );
    }
  };

  getWarningMessage = (value: string) => {
    return getValidationWarning(
      value,
      (input: string) => currency(input) && !isPartialDecimal(input),
    );
  };

  render() {
    const {
      decimalSeparator,
      decimalScale,
      currencySymbol,
      onSubmit,
      disabled,
      className,
      label,
      hideSymbol,
    } = this.props;
    const { internalValue } = this.state;

    const formattedValue =
      internalValue && decimalSeparator
        ? internalValue.toString().replace(".", decimalSeparator)
        : internalValue;

    const hasError =
      !isValueValid(decimalScale)(internalValue) ||
      isPartialDecimal(internalValue);

    const errorMessage = this.getWarningMessage(internalValue);

    const classNames = arrayToString([
      "input-group x-currency",
      hasError ? "x-has-error" : undefined,
      className,
    ]);

    return (
      <>
        <div className={classNames}>
          {!hideSymbol && (
            <div className="x-input-addon x-currency-symbol">
              {currencySymbol || "$"}
            </div>
          )}
          <InputWithSubmit
            disabled={disabled}
            onSubmit={onSubmit}
            submitOnBlur={true}
            placeholder={decimalPlaceholder(decimalSeparator, decimalScale)}
            label={label}
            value={formattedValue}
            onChange={this.onChange}
          />
        </div>
        {hasError && errorMessage && <AlertErrorTip message={errorMessage} />}
      </>
    );
  }
}

export const CurrencyInput = withDebounce(BaseCurrencyInput);
