import * as R from "ramda";
import { defaultFor } from "common";
import { TableConfig, TableValue } from "common/query/table/types";
import { Context } from "common/types/context";
import { mergeChain } from "common/merge";
import { Entity } from "common/entities/types";
import { Properties } from "common/types/records";
import { getGroupForSelect, hasSelectWithName } from "common/query/select";
import { isCustomOrSystemFk } from "common/entities/entity-column/functions";
import { EntityColumn } from "common/entities/entity-column/types";
import { getAdditionalCurrencyProps } from "common/form/group/functions";
import { getScaleAndPrecision } from "common/utils/decimal";
import {
  isOrderField,
  isSelectField,
  isSummaryField,
  OrderField,
  OrderItem,
  Query,
  SelectField,
  SelectItem,
} from "../types";
import { ColumnDefinition } from "../advanced-types";

const isAggregatedSelect = (item: SelectItem) =>
  isSelectField(item) && !!item.fn;

const hasAggregatedSelect = (select: SelectItem[]) =>
  select.some((s) => isAggregatedSelect(s));

const hasAggregatedOrSummarySelect = (select: SelectItem[]) =>
  select.some((s) => isAggregatedSelect(s) || isSummaryField(s));

const getGroupsFromSelect = (select: SelectItem[]) =>
  hasAggregatedOrSummarySelect(select) ? getGroupForSelect(select) : undefined;

const getSelectField = (orderField: OrderField): SelectField =>
  orderField.fn
    ? { name: orderField.name, fn: orderField.fn }
    : { name: orderField.name };

export const addSelectForOrder = (
  query: Query,
  select: SelectItem[] = [],
  order: OrderItem[] = [],
) => {
  const selectFields = select.filter(isSelectField);
  const orderFields = order.filter(isOrderField);
  const orderWithoutSelect = R.differenceWith(
    (o, s) => o.name === s.name,
    orderFields,
    selectFields,
  )[0];

  const selectsWithNewOrder =
    hasAggregatedSelect(select) && !!orderWithoutSelect
      ? R.append(getSelectField(orderWithoutSelect), select)
      : select;

  const group = getGroupsFromSelect(selectsWithNewOrder);

  return mergeChain(query)
    .set("order", order)
    .set("select", selectsWithNewOrder)
    .set("group", group)
    .output();
};

const addGroupForSelect = (
  query: Query,
  select: SelectItem[] = [],
  order: OrderItem[] = [],
) => {
  const group = getGroupsFromSelect(select);

  const orderWithoutRemovedSelects = hasAggregatedSelect(select)
    ? R.filter(
        (o) => (isOrderField(o) ? hasSelectWithName(o.name, select) : true),
        order,
      )
    : order;

  return mergeChain(query)
    .set("order", orderWithoutRemovedSelects)
    .set("select", select)
    .set("group", group)
    .output();
};

export const updateSelect = (
  query: Query,
  select: SelectItem[] = [],
  order: OrderItem[] = [],
) =>
  select.length > query.select.length
    ? addSelectForOrder(query, select, order)
    : addGroupForSelect(query, select, order);

export const getAdditionalProps = (
  entity: Entity,
  column: EntityColumn,
  data: Properties,
  context: Context,
) => getAdditionalCurrencyProps(entity, column, data, context);

export const hasFieldsWithTotals = (columnDefinitions: ColumnDefinition[]) =>
  R.any((c) => c?.column?.renderWithTotals === true, columnDefinitions);

export const getColumnTotal = (records: Properties[], column: EntityColumn) => {
  const total = R.reduce(
    (acc: { val: number; scale: number }, currentProperty) => {
      const value = parseFloat(currentProperty[column.name]) || 0;
      const { scale = 0 } = getScaleAndPrecision(value);
      return {
        val: acc.val + value,
        scale: Math.max(acc.scale, scale),
      };
    },
    { val: 0, scale: 0 },
    records,
  );
  return total.val?.toFixed(column.decimalScale || total.scale);
};

export const hasActionsCheck = (config: TableConfig = defaultFor()) =>
  (!!config.actions && R.keys(config.actions).length > 0) ||
  (!!config.actionsWithContent && R.keys(config.actionsWithContent).length > 0);

export const rowIsSelected = (properties: any, value: TableValue) =>
  !!value && R.includes(properties, value.selected || []);

export const isSelfReferencedNonFkField = (
  item: SelectItem,
  column: EntityColumn,
  entity: string,
  columnEntity: string,
) => {
  const isRelatedField = isSelectField(item) && !!item.path;
  return (
    !!(entity && columnEntity && column) &&
    entity === columnEntity &&
    !isCustomOrSystemFk(column) &&
    isRelatedField
  );
};
