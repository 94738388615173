import { ChangeEvent } from "react";
import { defaultFor } from "common";
import { validatePassword } from "common/api/authentication/user";
import { getError } from "common/api/error";
import { Culture } from "common/culture/supported-cultures";
import { RecoverLink } from "common/explicit-auth/forgot-password";
import { useResetEmail } from "common/explicit-auth/use-reset-email";
import { HorizontalField, Section } from "common/form/ui";
import { Context } from "common/types/context";
import { FileType } from "common/types/media";
import { CancellablePromise } from "common/types/promises";
import { PasswordPolicy } from "common/types/settings";
import { UiFormat } from "common/types/ui-format";
import { showErrorNotification } from "common/ui/notification";
import { email } from "common/validate";
import { AlertWarning } from "common/widgets/alert";
import { Email } from "common/widgets/email";
import { ImageWithUpload } from "common/widgets/image-with-upload";
import { StringInput } from "common/widgets/input-with-submit/string";
import { ValueProps } from "common/with-value-for";
import { CultureSettings } from "x/culture/culture-settings";
import { UserDetails } from "../types";

export type UploadAvatar = (file: FileType) => CancellablePromise<any>;
export type RemoveAvatar = () => void;

interface PropTypes extends ValueProps<UserDetails> {
  cultures: Culture[];
  passwordPolicy: PasswordPolicy;
  uploadAvatar: UploadAvatar;
  removeAvatar: RemoveAvatar;
  context: Context;
}

const passwordsMatch = (value: UserDetails): boolean =>
  value?.password === value.confirmPassword;

export const validDetails = (
  value: UserDetails,
  passwordPolicy: PasswordPolicy,
): boolean =>
  value &&
  email(value.userName) &&
  !validatePassword(passwordPolicy, value.password, value.userName) &&
  passwordsMatch(value);

export const Details = ({
  value = defaultFor<UserDetails>(),
  context,
  uploadAvatar,
  removeAvatar,
  cultures = [],
  passwordPolicy,
  onChange,
}: PropTypes) => {
  const {
    name,
    image,
    userName,
    currentPassword,
    password,
    confirmPassword,
    uiFormat,
  } = value;

  const { resetPasswordError, isSent, onSendClick } = useResetEmail(context);

  const onImageNotFound = () => {
    onChange({ ...value, image: undefined });
  };

  const onNameChange = (name: string) => {
    onChange({ ...value, name });
  };

  const onCurrentPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      currentPassword: e.target.value,
    });
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, password: e.target.value });
  };

  const onConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      confirmPassword: e.target.value,
    });
  };

  const onImageChange = (image: string) => {
    onChange({ ...value, image });
  };

  const onUserNameChange = (userName: string) => {
    onChange({ ...value, userName });
  };

  const onUiFormatChange = (uiFormat: UiFormat) => {
    onChange({ ...value, uiFormat });
  };

  const currentPasswordError =
    !currentPassword && (password || confirmPassword);

  const passwordPolicyError = validatePassword(
    passwordPolicy,
    password,
    userName,
  );

  const passwordConfirmError = confirmPassword && !passwordsMatch(value);

  return (
    <div className="x-user-profile-details">
      <div className="row">
        <div className="col-md-3 col-lg-2 x-image-uploader-wrapper">
          <ImageWithUpload
            context={context}
            allowClear={true}
            alt={_("User Avatar")}
            isLarge={true}
            onUpload={uploadAvatar}
            onRemove={removeAvatar}
            onError={onImageNotFound}
            value={image}
            onChange={onImageChange}
          />
        </div>
        <div className="col-md-9 col-lg-10">
          <Section legend={_("General information")}>
            {context.isPasswordExpired && (
              <AlertWarning
                message={_(
                  "Your eMaint X5 password has expired. Please update your password and click save. An updated password is required for continued use of your account.",
                )}
              />
            )}
            <div className="row">
              <div className="col-md-6">
                <HorizontalField label={_("Name")}>
                  <StringInput
                    className="qa-name"
                    placeholder={_("Name")}
                    value={name || ""}
                    onChange={onNameChange}
                  />
                </HorizontalField>
                <HorizontalField
                  className="qa-email"
                  label={_("Email (Username)")}
                  error={!userName || !email(userName)}
                >
                  <Email
                    value={userName}
                    validate={true}
                    onChange={onUserNameChange}
                  />
                </HorizontalField>
              </div>
              <div className="col-md-6">
                <div className="x-confirmPassword-wrapper">
                  {currentPasswordError && (
                    <span className="x-confirmPassword-no-match">
                      {_("Current password is required")}
                    </span>
                  )}
                  <HorizontalField label={_("Current password")}>
                    <form>
                      {" "}
                      {/* needs form tag for autoComplete to work ATM */}
                      {/* TODO create a password widget */}
                      <input
                        type="password"
                        className="qa-password"
                        placeholder={_("Current password")}
                        autoComplete="off"
                        value={currentPassword || ""}
                        onChange={onCurrentPasswordChange}
                      />
                    </form>
                  </HorizontalField>
                  <RecoverLink onSendClick={onSendClick} isSent={isSent} />
                  {resetPasswordError &&
                    showErrorNotification(getError(resetPasswordError))}
                </div>
                <div className="x-confirmPassword-wrapper">
                  {passwordPolicyError && (
                    <span className="x-confirmPassword-no-match">
                      {passwordPolicyError}
                    </span>
                  )}
                  <HorizontalField label={_("Password")}>
                    <form>
                      {" "}
                      {/* needs form tag for autoComplete to work ATM */}
                      {/* TODO create a password widget */}
                      <input
                        type="password"
                        className="qa-password"
                        placeholder={_("New password")}
                        autoComplete="off"
                        value={password || ""}
                        onChange={onPasswordChange}
                      />
                    </form>
                  </HorizontalField>
                </div>
                <div className="x-confirmPassword-wrapper">
                  {passwordConfirmError && (
                    <span className="x-confirmPassword-no-match">
                      {_("Passwords do not match")}
                    </span>
                  )}
                  <HorizontalField label={_("Confirm password")}>
                    <form>
                      {" "}
                      {/* needs form tag for autoComplete to work ATM */}
                      {/* TODO create a password widget */}
                      <input
                        type="password"
                        className="qa-confirm-password"
                        placeholder={_("Confirm new password")}
                        autoComplete="off"
                        value={confirmPassword || ""}
                        onChange={onConfirmPasswordChange}
                      />
                    </form>
                  </HorizontalField>
                </div>
              </div>
            </div>
          </Section>
          <Section
            className="x-culture-settings row"
            legend={_("Culture settings")}
          >
            <CultureSettings
              cultures={cultures}
              fieldClassName="col col-md-6"
              value={uiFormat}
              onChange={onUiFormatChange}
            />
          </Section>
        </div>
      </div>
    </div>
  );
};
